import emptyImage from "assets/images/empty.png";
import school2 from "assets/images/school1.jpg";
import upload from "assets/images/upload.png";
import bannerIcon from "assets/images/bannersvg.svg";
import icon1 from "assets/images/bannerIcon.png";
import image1 from "assets/images/image1.jpg";
import image2 from "assets/images/image2.jpg";
import image4 from "assets/images/image4.jpg";
import image5 from "assets/images/image5.jpg";
import prod1 from "assets/images/prod1.webp";
import prod2 from "assets/images/prod2.webp";
import prod3 from "assets/images/prod3.jpg";
import prod4 from "assets/images/prod4.webp";
import gov from "assets/images/gov.jpg";
import image3 from "assets/images/image3.jpeg";
import icon2 from "assets/images/icon2.svg";
import logo from "assets/images/appLogo.jpeg";
export const appColor = "rgb(0,90,0)";
export const appLight = "rgb(255, 245, 245)";
export const appLink = "#C3073F";
export const appDark = "#110000";
export const Grey1 = "rgb(150,150,150)";
export const empty = emptyImage;
export const uploader = upload;

export const OneIcon = icon1;
export const TwoIcon = icon2;
export const test1 = image1;
export const test2 = image2;
export const test3 = image3;
export const test4 = image4;
export const test5 = image5;
export const prodOne = prod1;
export const prodTwo = prod2;
export const prodThree = prod3;
export const prodFour = prod4;
export const governor = gov;
export const appLogo = logo;
export const hott = school2;
export const instagramUrl = "";
export const State = "Abia";
export const twitterUrl = "";
export const facebookUrl = "";
export const stateCodeNumber = "ASIN"; // "ANSSID/ASIN";
export const brandShort = "EAHS";
export const brandLong = "Explore Abia Hospitality Solutions";
export const bannerSvg = bannerIcon;
export const govName = "Dr Alex Otti";
export const govText = "The People's Manifesto";

export const ad1 = require("./assets/images/anambra/ad1.jpeg");
export const ad2 = require("./assets/images/anambra/ad2.jpeg");
export const ad3 = require("./assets/images/anambra/ad3.jpeg");
export const ad4 = require("./assets/images/anambra/ad4.jpeg");

export const CLOUDINARY = {
  UNSIGNED_UPLOAD_PRESET: "mediatek",
  BASE_URL: "https://api.cloudinary.com/v1_1",
  NAME: "mediatekk",
  FOLDERS: {
    IDENTITY_FILES: "identity-files",
  },
};

//#e89607
